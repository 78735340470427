import React from 'react';

export default ({fillColor}) => (
  <svg width="25" height="25" viewBox="0 0 25 25">
    <g fill={fillColor} fillRule="nonzero">
      <path d="M17.1875 0h-9.375C3.4984375 0 0 3.4984375 0 7.8125v9.375C0 21.5015625 3.4984375 25 7.8125 25h9.375C21.5015625 25 25 21.5015625 25 17.1875v-9.375C25 3.4984375 21.5015625 0 17.1875 0zm5.46875 17.1875c0 3.015625-2.453125 5.46875-5.46875 5.46875h-9.375c-3.015625 0-5.46875-2.453125-5.46875-5.46875v-9.375c0-3.015625 2.453125-5.46875 5.46875-5.46875h9.375c3.015625 0 5.46875 2.453125 5.46875 5.46875v9.375z" />
      <path d="M12.5 6C8.910375 6 6 8.910375 6 12.5S8.910375 19 12.5 19s6.5-2.910375 6.5-6.5S16.089625 6 12.5 6zm0 10.5625c-2.23925 0-4.0625-1.82325-4.0625-4.0625 0-2.240875 1.82325-4.0625 4.0625-4.0625s4.0625 1.821625 4.0625 4.0625c0 2.23925-1.82325 4.0625-4.0625 4.0625z" />
      <circle cx="19" cy="6" r="1" />
    </g>
  </svg>
);
