import invariant from 'invariant';
import {useRef, useLayoutEffect, useCallback, useState, useEffect} from 'react';
import {getDocument, getNearestScrollNode} from '../dom-utils';

export function getScrollRect(element) {
  let scrollingElement;
  if ('scrollingElement' in element && element.scrollingElement) {
    // eslint-disable-next-line prefer-destructuring
    scrollingElement = element.scrollingElement;
  } else if ('body' in element) {
    scrollingElement = element.body;
  } else {
    scrollingElement = element;
  }
  invariant(
    scrollingElement,
    `The provided element ${element} is not a scrolling element!`,
  );
  const {
    scrollTop: top,
    scrollLeft: left,
    scrollWidth: width,
    scrollHeight: height,
  } = scrollingElement;
  return {top, left, width, height};
}

export function getNode(node) {
  if (node) {
    node = 'node' in node ? node.node : node;
    node = 'element' in node ? node.element : node;
  }
  return node;
}

export function useForceUpdate() {
  const [, flipUpdateBit] = useState(false);
  const forceUpdate = useCallback(function forceUpdate() {
    flipUpdateBit(v => !v);
  }, []);
  return forceUpdate;
}

export function useNearestScrollNodeRef(ref) {
  const {current} = ref;
  const forceUpdate = useForceUpdate();
  const scrollRef = useRef(null);
  useLayoutEffect(() => {
    const doc = getDocument(current);
    const scrollNode = getNearestScrollNode(current);
    scrollRef.current = doc?.documentElement === scrollNode ? doc : scrollNode;
    if (scrollRef.current !== current) forceUpdate();
  }, [current, forceUpdate]);
  return scrollRef;
}

export function useSubscribableEvent(ref, event, options = {}) {
  // Note: we use state instead of a ref to track these values
  // because `useState` supports lazy instantiation (via a callback),
  // whereas`useRef` would have us creating and throwing away a `new Map()`
  // on every subsequent render.
  const [subscribers] = useState(() => new Map());
  const [listeners] = useState(() => new Map());

  const forceUpdate = useForceUpdate();

  const listenerOptions = useRef(options);
  // @ts-ignore
  const {capture, once, passive} = options;
  useEffect(
    function updateOptionsIfNecesssary() {
      listenerOptions.current = {capture, once, passive};
      if (listeners.size > 0) {
        // @ts-ignore
        for (const removeListenerIfPossible of listeners.values()) {
          removeListenerIfPossible(true);
        }
      }
    },
    [capture, once, passive, listeners],
  );

  const handleEvent = useCallback(
    function handleEvent(currentEvent) {
      if (subscribers.size) {
        // @ts-ignore
        for (const subscriber of subscribers.keys()) {
          subscriber(currentEvent);
        }
      }
    },
    [subscribers],
  );

  useLayoutEffect(function addListenerIfNecessary() {
    if (!ref.current) return;
    const eventTarget = ref.current;
    const eventOptions = listenerOptions.current;
    if (!listeners.has(eventTarget) && subscribers.size > 0) {
      eventTarget.addEventListener(event, handleEvent, eventOptions);
      listeners.set(eventTarget, function removeListenerIfPossible(force) {
        if (force || subscribers.size <= 0) {
          eventTarget.removeEventListener(event, handleEvent, eventOptions);
          listeners.delete(eventTarget);
        }
      });
    }
  });

  useEffect(() => {
    function cleanup() {
      if (listeners.size > 0) {
        // @ts-ignore
        for (const removeListenerIfPossible of listeners.values()) {
          removeListenerIfPossible(true);
        }
      }
    }
    return cleanup;
  }, [listeners]);

  const subscribe = useCallback(
    function subscribe(handler) {
      if (!subscribers.has(handler)) {
        subscribers.set(handler, true);
      }
      function unsubscribe() {
        if (subscribers.has(handler)) {
          subscribers.delete(handler);
          forceUpdate();
        }
      }
      forceUpdate();
      return unsubscribe;
    },
    [subscribers, forceUpdate],
  );
  return subscribe;
}

const SCROLL = 'scroll';
const LISTENER_OPTIONS = {passive: true};

export function useScrollEffect(scrollRef, listener, deps): void {
  const handler = useCallback(listener, deps);
  const subscribe = useSubscribableEvent(scrollRef, SCROLL, LISTENER_OPTIONS);
  useEffect(() => subscribe(handler), [subscribe, handler]);
}
