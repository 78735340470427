// @flow

import React, {Fragment} from 'react';
import {Row} from 'jsxstyle';
import {useSpring, animated} from 'react-spring';
import useHover from '../../libs/use-hover';

import Link from '@components/Link';
import MenuDropdown from './MenuDropdown';
import DropdownMenuIcon from '@components/icons/DropdownMenuIcon';
import {GREY, LIGHT_GREY, MID_GREY} from '@styles/colors';

type Props = {
  href: string,
  label: string,
  text: string,
  icon?: boolean,
  dropdownItems?: [],
  target?: string
};

const AnimatedDropdownLink = animated(Row);

export default function MenuDropdownLink({href, label, text, icon, dropdownItems, target}: Props) {
  const [isHovering, hoverProps] = useHover({
    "mouseEnterDelayMS": 0,
    "mouseLeaveDelayMS": 0,
  });

  return (
    <AnimatedDropdownLink
      props={{...hoverProps}}
      component="li"
      width="100%"
      height="30px"
      alignItems="center"
      justifyContent="flex-start"
      willChange="background"
      className='header-button-transition'
    >
      <Fragment>{icon && <DropdownMenuIcon margin="0 0 0 5px" />}</Fragment>
        <Link
          href={href}
          label={label}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: GREY,
            textDecoration: 'none',
            width: '100%',
            height: '100%',
            padding: '0 10px',
          }}
        >
          {text}
        </Link>
      {dropdownItems &&
          <MenuDropdown
            dropdownItems={dropdownItems}
            updatedNav={false}
            expanded={isHovering}
            subDropDown={true}
          />
      }
    </AnimatedDropdownLink>
  );
}
