// @flow

import React from 'react';
import ScrollMonitor from '../../libs/scroll-monitor';
import Menu from './Menu';
 

export default ({
  menuBackground,
  menuItemColor,
  contactUsQuery
}) => {
  return(
    <ScrollMonitor direction position vertical bounds={{bottom: 190}}>
      {
        currentScrollMonitorState => {
          return (
            <Menu
              scrollRef={currentScrollMonitorState.scrollRef}
              menuBackground={menuBackground}
              topRelativePosition={currentScrollMonitorState.position.top}
              menuItemColor={menuItemColor}
              contactUsQuery={contactUsQuery}
            />
          );
        }
      }
    
    </ScrollMonitor>
);
}