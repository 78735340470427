// @flow

import React, {Component} from 'react';
import {Col} from 'jsxstyle';

import MenuDropdown from './MenuDropdown';
import Link from '@components/Link';
import {ORANGE} from '@styles/colors';

type Props = {
  linkHref: string,
  linkText: string,
  updatedNav: boolean,
  linkColor?: string,
  opacity?: string,
  dropdownItems?: Object[],
};

type State = {
  menuShown: boolean,
};

const mediaQueries = {
  sm: 'screen and (max-width: 1024px)',
  lg: 'screen and (min-width: 1280px)',
};

export default class MainLink extends Component<Props, State> {
  state = {
    menuShown: false,
  };

  handleMouseEnter = () => {
    this.setState({
      menuShown: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      menuShown: false,
    });
  };

  handleKeyPress = (event: KeyboardEvent) => {
    event.preventDefault();
    if (event.key === ' ') {
      if (this.state.menuShown) {
        this.handleMouseLeave();
      } else {
        this.handleMouseEnter();
      }
    }
  };

  render() {
    const {
      linkHref,
      linkText,
      linkColor,
      dropdownItems,
      updatedNav,
      opacity,
    } = this.props;
    return (
      <Col
        component="li"
        height="100%"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        listStyle="none"
        opacity={opacity}
        mediaQueries={mediaQueries}
        margin="0 25px"
        smMargin="0"
        smHeight="auto"
        smPadding="20px 0"
        smAlignItems="flex-start"
        smBorderBottom={`1px solid #6C6D6F`}
        smWidth="100%"
        props={{
          onMouseEnter: this.handleMouseEnter,
          onMouseLeave: this.handleMouseLeave,
          onKeyPress: event => this.handleKeyPress(event),
        }}
      >
        <Link
          href={linkHref}
          style={{
            textTransform: 'capitalize',
            textDecoration: 'none',
            fontWeight: 'bold',
            color: linkColor,
            height: '20px',
          }}
          activeStyle={{
            borderBottom: `2px solid ${ORANGE}`,
          }}
        >
          {linkText}
        </Link>
        {dropdownItems && (
          <MenuDropdown
            dropdownItems={dropdownItems}
            updatedNav={updatedNav}
            expanded={this.state.menuShown}
          />
        )}
      </Col>
    );
  }
}
