import {useState, useEffect, useRef, useCallback} from 'react';
import useRefCallback from '../use-ref-callback';
import {useNearestScrollNodeRef, useScrollEffect} from './utils';

const SCROLL_TIMEOUT = 60;

function useScrolling(providedRef) {
  // Keep track of whether or not the nearest scrollable container is scrolling.
  const [scrolling, setScrolling] = useState(false);

  // Keep a ref to the nearest scrollable container.
  const [ref, setRef] = useRefCallback(null);
  if (providedRef) setRef(providedRef.current);
  const scrollRef = useNearestScrollNodeRef(ref);

  // Keep a ref to a timeout id.
  const scrollTimeout = useRef(null);

  const clearScrollTimeout = useCallback(function clearScrollTimeout() {
    if (scrollTimeout.current !== null) {
      clearTimeout(scrollTimeout.current);
      scrollTimeout.current = null;
    }
  }, []);

  const stopScrolling = useCallback(
    function stopScrolling() {
      clearScrollTimeout();
      setScrolling(false);
    },
    [clearScrollTimeout],
  );

  const startScrolling = useCallback(
    function startScrolling() {
      clearScrollTimeout();
      setScrolling(true);
      scrollTimeout.current = setTimeout(stopScrolling, SCROLL_TIMEOUT);
    },
    [clearScrollTimeout, stopScrolling],
  );

  useEffect(() => {
    return function cleanup() {
      clearScrollTimeout();
    };
  }, [clearScrollTimeout]);

  // Subscribe to scroll events on the nearest scrolling element,
  // calling the `startScrolling` callback whenever a scroll event occurs.
  useScrollEffect(scrollRef, startScrolling, [startScrolling]);

  // If a ref has been provided, just return the `scrolling` value.
  // If a ref has not not been provided, return a callback ref
  // along with the `scrolling` value.
  return providedRef ? scrolling : [scrolling, setRef];
}

export default useScrolling;
