import {useCallback, useState, useRef} from 'react';

function useRefCallback(initialValue, innerRef) {
  const [, f] = useState(false);
  const forceUpdate = useCallback(() => f(v => !v), []);
  const ref = useRef(initialValue);
  const callback = useCallback(
    node => {
      if (typeof innerRef === 'function') {
        innerRef(node);
      } else if (innerRef && 'current' in innerRef) {
        innerRef.current = node;
      }
      if (ref.current !== node) {
        ref.current = node;
        forceUpdate();
      }
    },
    [innerRef, forceUpdate],
  );
  return [ref, callback];
}

export default useRefCallback;
