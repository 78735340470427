import React from 'react';
import {BLUE} from '@styles/colors';

export default ({fillColor}) => (
  <svg width="166" height="45" viewBox="0 0 166 45">
    <path
      fill={fillColor ? fillColor : BLUE}
      fillRule="nonzero"
      d="M123.766784 31.5584416h-13.56742V13.4415584h13.450106v2.8636364h-10.259152v4.6753247h9.091873v2.8636363h-9.091873v4.8506494h10.376466v2.8636364zm12.153781 0h-3.190954V13.4415584h8.106431c2.287633 0 4.04735.6896104 5.220495 1.8292208 1.002511 1.0480923 1.54495 2.4507251 1.507491 3.898052v.0525974c0 2.9688311-1.789046 4.7512987-4.305441 5.4701298l4.909611 6.8668832h-3.783392l-4.457951-6.3h-3.988692l-.017598 6.3zm0-9.111039h4.663251c2.281767 0 3.730601-1.1688312 3.730601-3.0214286v-.0525974c0-1.9402597-1.396042-2.9980519-3.754064-2.9980519h-4.639788v6.0720779zm-48.2455827-3.7461039l10.0069258 12.8571429h2.6689049V13.4415584h-3.1381628v12.5181819l-9.7195053-12.5181819h-2.9328622v18.1168832h3.1322968l-.0175972-12.8571429zm-34.0798586 9.9935065H43.2010601v-4.8506494h9.0977385v-2.8402597h-9.0977385v-4.6753247h10.2650176v-2.887013h-13.450106v18.1168832h13.579152v-2.8636364zM166 0v45H0V0h166zm-3.003251 2.98636364H2.99738516V42.0136364H162.996749V2.98636364zM67.0217668 31.5584416h3.2144169V16.4045455h5.7484099v-2.9629871H61.2674912v2.922078h5.7542756v15.1948052zm-46.0048763-7.3811689h9.15053v-2.8928571h-9.15053v-4.9207792h10.3178091v-2.922078h-13.491166v18.1168832h3.1850883l-.0117314-7.3811689z"
    />
  </svg>
);
