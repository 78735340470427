import React from 'react';

export default ({margin}) => (
  <i style={{margin}}>
    <svg width="19" height="18" viewBox="0 0 19 25">
      <path
        fill="#4E4E4E"
        fillRule="nonzero"
        d="M9.49960388 0C4.26177603 0 0 4.12897085 0 9.20408957c0 1.32378823.2796642 2.58770673.83080601 3.75695923 2.37516505 5.0354614 6.92901719 10.3518503 8.26844761 11.8611327C9.19960526 24.9350131 9.3459074 25 9.49986796 25c.15396055 0 .30026269-.0649869.40061434-.1778185 1.3389022-1.5090265 5.8927544-6.8251596 8.2687117-11.8611327C18.7205999 11.7917963 19 10.5278778 19 9.20408957 18.9994718 4.12897085 14.7376958 0 9.49960388 0zm0 13.9847204c-2.72085008 0-4.93466024-2.1448236-4.93466024-4.78088668 0-2.63631898 2.21381016-4.78114255 4.93466024-4.78114255 2.72085002 0 4.93466022 2.14482357 4.93466022 4.78114255.0002641 2.63606308-2.2135461 4.78088668-4.93466022 4.78088668z"
      />
    </svg>
  </i>
);
