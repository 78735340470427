import * as ScrollMonitor from './ScrollMonitor';
import * as scrolling from './useScrolling';
import * as position from './useScrollPosition';
import * as direction from './useScrollDirection';
import * as intersection from './useScrollIntersection';
import * as utils from './utils';

export const useScrolling = scrolling.default;

export const useScrollPosition = position.default;
export const {getScrollPosition} = position;

export const {ScrollDirection} = direction;
export const {getScrollDirection} = direction;
export const useScrollDirection = direction.default;

export const {getIntersects} = intersection;
export const useScrollIntersection = intersection.default;

export const {useNearestScrollNodeRef} = utils;

export default ScrollMonitor.default;
