import isClient from './isClient';
import isDOMInstance from './isDOMInstance';
import findAncestor from './findAncestor';

export default function getNearestScrollNode(
  node: Node | null,
): HTMLElement | null {
  if (!isClient) return null;
  if (isDOMInstance(node, Document)) return node.documentElement;
  if (!isDOMInstance(node, HTMLElement)) return null;
  const ancestor = findAncestor(node, nodeToFind => {
    const {overflowX, overflowY} = getComputedStyle(nodeToFind);
    if (overflowX === 'scroll') return true;
    return overflowY === 'scroll';
  });
  return ancestor ?? node.ownerDocument?.documentElement ?? null;
}
