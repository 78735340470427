// @flow

import React, {Component, Fragment} from 'react';
import {Block, Row, Col} from 'jsxstyle';
import WindowsizeMonitor from '../WindowSizeMonitor';

import Link from '@components/Link';
import Button from '@components/Button';
import Logo from '@components/icons/Logo';
import MenuLink from './MenuLink';
import DropdownIcon from '@components/icons/DropdownIcon';
import Call from '@components/icons/Call';
import {ORANGE, BLUE, WHITE, LIGHT_GREY, TRANSPARENT} from '@styles/colors';
import {NAVIGATION_DATA} from './navigationData';

type Props = {
  menuBackground?: string,
  menuItemColor?: string,
  scrollRef?: Object,
  topRelativePosition: number,
  contactUsQuery?: string
};

type State = {
  menuExpanded: boolean,
};

const mediaQueries = {
  sm: 'screen and (max-width: 1024px)',
  md: 'screen and (max-width: 1070px)',
  lg: 'screen and (min-width: 1280px)',
};

export default class Menu extends Component<Props, State> {
  state = {
    menuExpanded: false,
  };

  handleMenuToggle = () => {
    if (this.state.menuExpanded) {
      this.setState({
        menuExpanded: false,
      });
    } else {
      this.setState({
        menuExpanded: true,
      });
    }
  };

  render() {
    const {
      menuBackground,
      menuItemColor,
      scrollRef,
      topRelativePosition,
      contactUsQuery,
    } = this.props;
    const updatedNav: boolean = topRelativePosition > 200;

    return (
      <WindowsizeMonitor>
        {({width}) => (
          <Row
            component="nav"
            height="70px"
            padding={updatedNav ? '0' : '30px'}
            width={updatedNav ? '100%' : 'calc(100% - 60px)'}
            background={
              menuBackground ? menuBackground : updatedNav ? WHITE : TRANSPARENT
            }
            boxShadow={
              updatedNav ? '-2px 1px 25px 0 rgba(45, 50, 52, 0.06)' : null
            }
            justifyContent="space-between"
            alignItems="center"
            position="fixed"
            zIndex="5"
            props={{ref: scrollRef, role: 'navigation'}}
            mediaQueries={mediaQueries}
            smVisibility="visible !important"
            smBackground="white !important"
            smPadding="5px !important"
            smWidth="calc(100% - 10px) !important"
          >
            <Block margin="0 15px">
              <Link href="/" ariaLabel="Navigate to homepage">
                <Logo
                  fillColor={
                    width <= 1024
                      ? BLUE
                      : menuItemColor
                      ? menuItemColor
                      : updatedNav
                      ? BLUE
                      : WHITE
                  }
                />
              </Link>
            </Block>
            {width <= 1024 ? (
              <Row
                display="none"
                mediaQueries={mediaQueries}
                smDisplay="flex"
                width="70px"
                justifyContent="space-around"
                alignItems="center"
              >
                <Link
                  href="tel: 1-212-427-9700"
                  ariaLabel="Call us at 2124279700 to get more info"
                >
                  <Call />
                </Link>
                <button
                  name="toggle navigation menu"
                  aria-label="toggle navigation menu"
                  style={{
                    WebkitAppearance: 'none',
                    background: TRANSPARENT,
                    cursor: 'pointer',
                    border: 'none',
                  }}
                  onClick={this.handleMenuToggle}
                >
                  <DropdownIcon />
                </button>
              </Row>
            ) : null}

            <Fragment>
              {width > 1024 ? (
                <Row
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  height="100%"
                >
                  <Row
                    component="ul"
                    listStyle="none"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    {NAVIGATION_DATA.map(
                      ({id, linkHref, linkText, dropdownItems}) => (
                        <MenuLink
                          key={id.toString()}
                          linkHref={linkHref}
                          linkText={linkText}
                          linkColor={
                            menuItemColor
                              ? menuItemColor
                              : updatedNav
                              ? BLUE
                              : WHITE
                          }
                          dropdownItems={dropdownItems}
                          updatedNav={updatedNav}
                        />
                      ),
                    )}
                  </Row>
                  <Row alignItems="center" justifyContent="center">
                    <Link
                      href="tel: 1-212-427-9700"
                      style={{
                        color: menuItemColor
                          ? menuItemColor
                          : updatedNav
                          ? BLUE
                          : WHITE,
                        fontSize: '1rem',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        textTransform: 'capitalize',
                        textDecoration: 'underline',
                        marginRight: '50px',
                      }}
                    >
                      {'Call: 212.427.9700'}
                    </Link>
                    <Block
                      display="flex"
                      flexWrap="nowrap"
                      mediaQueries={mediaQueries}
                      mdMargin={'0 0 0 -40px'}
                    >
                     {/* <Block
                        display="block !important"
                        margin={'0 15px 0 0 !important'}
                        mediaQueries={mediaQueries}
                        lgDisplay="inline-block !important"
                        lgMargin={'0 !important'}
                      >
                        <Button
                          buttonLink="/virtual-tours/"
                          buttonText="Virtual Tours"
                          buttonColor={ORANGE}
                          buttonHeight={'50px'}
                        />
                      </Block>*/}
                      <Block
                        display="block !important"
                        margin="0 15px 0 0 !important"
                        mediaQueries={mediaQueries}
                        lgDisplay="inline-block !important"
                        lgMargin="0 15px 0 20px !important"
                      >
                        <Button
                          buttonLink={contactUsQuery ? "/contact/" + contactUsQuery : "/contact/"}
                          buttonText="contact us"
                          buttonColor={ORANGE}
                          buttonHeight={'50px'}
                        />
                      </Block>
                    </Block>
                  </Row>
                </Row>
              ) : this.state.menuExpanded ? (
                <Col
                  height="calc(100vh - 100px)"
                  width="calc(100vw - 100px)"
                  top="70px"
                  left="0"
                  right="0"
                  bottom="0"
                  position="absolute"
                  background={LIGHT_GREY}
                  padding="50px"
                  justifyContent="space-between"
                >
                  <Col
                    alignItems="flex-start"
                    justifyContent="center"
                    mediaQueries={mediaQueries}
                  >
                    {NAVIGATION_DATA.map(
                      ({id, linkHref, linkText, dropdownItems}) => (
                        <MenuLink
                          key={id.toString()}
                          linkHref={linkHref}
                          linkText={linkText}
                          linkColor={BLUE}
                          dropdownItems={dropdownItems}
                          updatedNav={updatedNav}
                        />
                      ),
                    )}
                  </Col>
                  <Col
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column-reverse"
                  >
                    <Link
                      href="tel: 1-212-427-9700"
                      style={{
                        color: BLUE,
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        textDecoration: 'underline',
                        margin: '40px 0',
                      }}
                    >
                      {'Call us today: 212.427.9700'}
                    </Link>
                    <Block marginTop="20px" width="100%">
                      <Button
                        buttonLink="/virtual-tours/"
                        buttonText="Virtual Tours"
                        buttonColor={ORANGE}
                        buttonHeight={'50px'}
                        buttonWidth="100%"
                      />
                    </Block>
                    <Button
                      buttonLink="/contact/"
                      buttonText="contact us"
                      buttonColor={ORANGE}
                      buttonHeight="50px"
                      buttonWidth="100%"
                    />
                  </Col>
                </Col>
              ) : null}
            </Fragment>
          </Row>
        )}
      </WindowsizeMonitor>
    );
  }
}
