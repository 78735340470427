export const NAVIGATION_DATA = [
  {
    id: 1,
    linkHref: '/neighborhoods/',
    linkText: 'neighborhoods',
    dropdownItems: [
      {
        id: 1,
        href: 'https://boldanditalic.com',
        label: 'Long Island City',
        text: 'Long Island City',
        icon: true,
        target: '_blank',
      },
      {
        id: 2,
        href: '/neighborhoods/nomad/',
        label: 'NoMad',
        text: 'NoMad',
        icon: true,
      },
      {
        id: 3,
        href: '/neighborhoods/upper-east-side/',
        label: 'Upper East Side',
        text: 'Upper East Side',
        icon: true,
      },
      {
        id: 4,
        href: 'https://266w96.com',
        label: 'Upper West Side',
        text: 'Upper West Side',
        icon: true,
        target: '_blank',
      },
    ],
  },
  {
    id: 2,
    linkHref: '#',
    linkText: 'properties',
    dropdownItems: [
      {
        id: 1,
        href: 'https://theepic.com/',
        label: 'The Epic',
        text: 'The Epic',
      },
      {
        id: 2,
        href: 'https://266w96.com',
        label: '266W96',
        text: '266W96',
        target: '_blank',
      },
      {
        id: 3,
        href: 'https://boldanditalic.com',
        label: 'The Bold',
        text: 'The Bold',
        target: '_blank',
      },
      {
        id: 4,
        href: 'https://85eastendavenue.com',
        label: '85 East End',
        text: '85 East End',
        target: '_blank',
      },
      {
        id: 5,
        href: 'https://501east74street.com/',
        label: '501 East 74th',
        text: ' 501 East 74th',
        target: '_blank',
      },
    ],
  },  
  {
    linkHref: '/about/',
    linkText: 'about us',
    id: 3,
    dropdownItems: [
      {
        id: 1,
        href: '/about/team/',
        label: 'Team',
        text: 'Team',
      },
      {
        id: 2,
        href: '/about/partnerships/',
        label: 'Partnerships',
        text: 'Partnerships',
      },
      {
        id: 3,
        href: '/about/projects/',
        label: 'Projects',
        text: 'Projects',
        dropdownItems: [
          {
            id: 31,
            href: '/about/projects/#rental-properties',
            label: 'Rental Properties',
            text: 'Rental Properties'
          },{
            id: 32,
            href: '/about/projects/#coming-soon',
            label: 'Coming Soon',
            text: 'Coming Soon'
          },{
            id: 33,
            href: '/about/projects/#condo-properties',
            label: 'Condo Properties',
            text: 'Condo Properties'
          }
        ]
      },
    ],
  },
  {
    id: 4,
    linkHref: '/lifestyle/',
    linkText: 'lifestyle',
  },
];