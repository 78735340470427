import {useState, useRef} from 'react';
import useRefCallback from '../use-ref-callback';
import {getScrollPosition} from './useScrollPosition';
import {useNearestScrollNodeRef, useScrollEffect} from './utils';

const INITIAL_SCROLL_DIRECTION = {
  vertical: null,
  horizontal: null,
};

export const DOWN = 'down';
export const UP = 'up';
export const LEFT = 'left';
export const RIGHT = 'right';

export function getScrollDirection(position, lastPosition) {
  let vertical = null;
  let horizontal = null;

  if (typeof position.top === 'number') {
    vertical = position.top > 0 ? DOWN : null;
    if (lastPosition && typeof lastPosition.top === 'number') {
      if (lastPosition.top === position.top) vertical = null;
      else if (lastPosition.top > position.top) vertical = UP;
    }
  }

  if (typeof position.left === 'number') {
    horizontal = position.left > 0 ? RIGHT : null;
    if (lastPosition && typeof lastPosition.left === 'number') {
      if (lastPosition.left === position.left) horizontal = null;
      else if (lastPosition.left > position.left) horizontal = LEFT;
    }
  }

  return {vertical, horizontal};
}

function useScrollDirection(providedRef) {
  const scrollPosition = useRef(null);
  const [direction, setDirection] = useState(INITIAL_SCROLL_DIRECTION);

  // Keep a ref to the nearest scrollable container.
  const [ref, setRef] = useRefCallback(null);
  if (providedRef) setRef(providedRef.current);
  const scrollRef = useNearestScrollNodeRef(ref);

  // Subscribe to scroll events on the nearest scrolling element,
  // calling the handler whenever a scroll event occurs.
  useScrollEffect(
    scrollRef,
    function handleScrollChange(event) {
      const nextPosition = getScrollPosition(event);
      const next = getScrollDirection(nextPosition, scrollPosition.current);
      scrollPosition.current = nextPosition;
      setDirection(prev => {
        let state = prev;
        if (next.horizontal !== null && next.horizontal !== state.horizontal) {
          state = {...state, horizontal: next.horizontal};
        }
        if (next.vertical !== null && next.vertical !== state.vertical) {
          state = {...state, vertical: next.vertical};
        }
        return state;
      });
    },
    [],
  );

  // If a ref has been provided, just return the `direction` value.
  // If a ref has not not been provided, return a callback ref
  // along with the `direction` value.
  return providedRef ? direction : [direction, setRef];
}

export default useScrollDirection;
