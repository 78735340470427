// @flow

import React, {Fragment} from 'react';
import HeaderLight from '@components/HeaderLight';
import Header from '@components/Header';
import Footer from '@components/Footer';
//import CovidPopupMessage from '@components/CovidPopupMessage';

// import useUUID from '@hooks/useUUID';

import type {Node, Element} from 'react';

import '../styles/reset.css';
import '../styles/style.css';

type Props = {
  children: Node,
  menuItemColor?: string,
  menuBackground?: string,
  lightMenu?: string,
  noCovid?: string,
  contactUsQuery?: string
};

export default ({
  children,
  menuItemColor,
  menuBackground,
  lightMenu,
  noCovid,
  contactUsQuery,
  hideHeaderAndFooter = false // New prop with default value
}: Props): Element<*> => {
  return (
    <Fragment>
      {!hideHeaderAndFooter && (lightMenu
        ? <HeaderLight menuItemColor={menuItemColor} menuBackground={menuBackground} contactUsQuery={contactUsQuery} />
        : <Header menuItemColor={menuItemColor} menuBackground={menuBackground} contactUsQuery={contactUsQuery} />
      )}
      
      {children}

      {!hideHeaderAndFooter && <Footer />}
    </Fragment>
  );
};
