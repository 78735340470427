// @flow

import React from 'react';
import ScrollMonitor from '../../libs/scroll-monitor';
import Menu from './Menu';

import type {Element} from 'react';

type NavigationProps = {
  menuBackground?: string,
  menuItemColor?: string,
  contactUsQuery?: string
};

export default ({
  menuBackground,
  menuItemColor,
  contactUsQuery,
}: NavigationProps): Element<*> => (
  <ScrollMonitor direction position vertical bounds={{bottom: 190}}>
    {currentScrollState => (
      <Menu
        scrollRef={currentScrollState.scrollRef}
        menuBackground={menuBackground}
        topRelativePosition={currentScrollState.top}
        menuItemColor={menuItemColor}
        contactUsQuery={contactUsQuery}
      />
    )}
  </ScrollMonitor>
);
