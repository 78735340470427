import {useState, useMemo} from 'react';
import useRefCallback from '../use-ref-callback';
import useScrolling from './useScrolling';
import useScrollPosition from './useScrollPosition';
import useScrollDirection from './useScrollDirection';
import useScrollIntersection from './useScrollIntersection';

function ScrollMonitor(props) {
  const {innerRef} = props;
  const [scrollRef, setScrollRef] = useRefCallback(null, innerRef);
  const scrolling = useScrolling(scrollRef);
  const direction = useScrollDirection(scrollRef);
  const intersects = useScrollIntersection(scrollRef, props.intersects || []);

  const [position, setPosition] = useState({
    top: null,
    left: null,
  });
  useScrollPosition(scrollRef, setPosition);

  const scrollingEnabled =
    props.scrolling || Boolean(props.onStart || props.onChange || props.onEnd);
  const positionEnabled = props.position || Boolean(props.onChange);
  const directionEnabled = Boolean(props.direction);
  const intersectionEnabled = Boolean(props.intersects);

  // Create the new state.
  const state = useMemo(() => {
    const newState = {scrollRef: setScrollRef};
    if (scrollingEnabled) newState.scrolling = scrolling;
    if (positionEnabled) newState.position = position;
    if (directionEnabled) newState.direction = direction;
    if (intersectionEnabled) newState.intersects = intersects;
    return newState;
  }, [
    setScrollRef,
    scrolling,
    position,
    direction,
    intersects,
    scrollingEnabled,
    positionEnabled,
    directionEnabled,
    intersectionEnabled,
  ]);

  // Call the `onStart` hook if the scroll container is scrolling.
  const {onStart} = props;
  useMemo(() => {
    if (typeof onStart === 'function' && scrolling) {
      onStart();
    }
  }, [onStart, scrolling]);

  // Call the `onChange` hook if the scroll container is scrolling.
  const {onChange} = props;
  useMemo(() => {
    if (typeof onChange === 'function' && scrolling) {
      onChange(state);
    }
  }, [onChange, scrolling, state]);

  // Call the `onEnd` hook if the scroll container is not scrolling.
  const {onEnd} = props;
  // Keep track whether or not the scroll container
  // was scrolling during the previous `onEnd` effect.
  const [wasScrolling, setWasScrolling] = useState(false);
  useMemo(() => {
    // Call the `onEnd` hook if scrolling just stopped.
    if (typeof onEnd === 'function' && wasScrolling && !scrolling) {
      onEnd();
    }
    // Set whether or not the scroll container was scrolling during this effect.
    setWasScrolling(scrolling);
  }, [onEnd, wasScrolling, scrolling]);

  // Call the render prop with the current state.
  const {children: render} = props;
  return render(state);
}

export default ScrollMonitor;
