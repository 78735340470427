import {useRef} from 'react';
import useRefCallback from '../use-ref-callback';
import {getScrollRect, useNearestScrollNodeRef, useScrollEffect} from './utils';
import {isDOMInstance} from '../dom-utils';

export function getScrollPosition(event) {
  const target = event.currentTarget;
  if (isDOMInstance(target, HTMLElement) || isDOMInstance(target, Document)) {
    const rect = getScrollRect(target);
    return {top: rect.top, left: rect.left};
  }
  return {top: null, left: null};
}

function useScrollPosition(providedRefOrHandler, handler) {
  let providedRef;
  if ('current' in providedRefOrHandler) {
    providedRef = providedRefOrHandler;
  } else {
    handler = providedRefOrHandler;
  }
  // Keep a ref to the nearest scrollable container.
  const [ref, setRef] = useRefCallback(null);
  if (providedRef) setRef(providedRef.current);
  const scrollRef = useNearestScrollNodeRef(ref);

  const changeHandler = useRef(handler);
  changeHandler.current = handler;

  // Subscribe to scroll events on the nearest scrolling element,
  // calling the handler whenever a scroll event occurs.
  useScrollEffect(
    scrollRef,
    function handleEvent(event: Event) {
      const cb = changeHandler.current;
      if (typeof cb === 'function') {
        const position = getScrollPosition(event);
        return cb(position);
      }
    },
    [],
  );

  // If a ref has been provided, return nothing.
  // If a ref has not not been provided, return a callback ref.
  if (!providedRef) return setRef;
}

export default useScrollPosition;
