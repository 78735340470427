import React from 'react';

type Props = {
  fill: string,
};

export default ({fill, ...rest}: Props) => (
  <svg width="22" height="22" viewBox="0 0 18 18" {...rest}>
    <defs>
      <path id="a" d="M0 0h17.2347872v17.2267H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        fill={fill ? fill : '#004363'}
        d="M4.18238719 0c-.769 0-1.491.299-2.036.842l-.57.571c-3.516 3.517-.209 7.37.888 8.467l1.647 1.648 3.24 3.239c1.632 1.633 3.28200001 2.46 4.90300001 2.46.908 0 2.265-.272 3.565-1.572l.568-.57c.9-.897 1.106-2.258.509-3.384-.623-1.179-2.131-2.15-2.75-2.513-.489-.278-1.003-.419-1.506-.419-.762 0-1.455.319-1.934.873L8.35238719 7.288l-.763-.763c.374-.323.645-.748.779-1.231.197-.71.086-1.487-.313-2.187-.375-.641-1.346-2.149-2.525-2.772-.414-.219-.88-.335-1.348-.335m0 2c.141 0 .282.034.412.103.804.425 1.724 1.993 1.724 1.993.202.355.224.783-.136.986 0 0-.612.509-.699.639-.402.592-.386 1.14.168 1.694l1.286 1.287 2.88 2.879c.30200001.303.60500001.445.91300001.445.254 0 .513-.096.78-.277.13-.087.64-.699.64-.699.111-.199.292-.281.49-.281.162 0 .336.054.495.145 0 0 1.568.919 1.993 1.723.182.342.118.762-.155 1.035l-.568.569c-.692.691-1.417.986-2.151.986-1.178 0-2.37800001-.763-3.48900001-1.874l-3.24-3.239-1.647-1.648c-1.802-1.802-2.689-3.837-.887-5.639l.568-.569c.17-.169.394-.258.623-.258"
        mask="url(#b)"
      />
    </g>
  </svg>
);
