// @flow

import React from 'react';
import {Col, Row, Block} from 'jsxstyle';

import Link from '@components/Link';
import Logo from '@components/icons/Logo';
import Brand from '@components/icons/Brand';
import Eho from '@components/icons/Eho';
import Instagram from '@components/icons/Instagram';
import {WHITE, BLUE} from '@styles/colors';

import type {Element} from 'react';

const mediaQueries = {
  sm: 'screen and (max-width: 1024px)',
  lg: 'screen and (min-width: 1280px)',
};

export default (): Element<*> => (
  <Col component="footer" background={BLUE} padding="40px">
    <Row
      justifyContent="space-between"
      alignItems="center"
      marginBottom="30px"
      mediaQueries={mediaQueries}
      smFlexDirection="column"
    >
      <Row
        alignItems="center"
        justifyContent="center"
        mediaQueries={mediaQueries}
        smFlexDirection="column"
        smMarginBottom="30px"
      >
        <Link href="/" ariaLabel="Navigate to homepage">
          <Logo fillColor={WHITE} />
        </Link>
        <Col
          height="45px"
          mediaQueries={mediaQueries}
          smHeight="auto"
          smFlexDirection="row"
          smFlexWrap="wrap"
          smJustifyContent="center"
          smAlignItems="center"
        >
          <Row
            marginBottom="10px"
            mediaQueries={mediaQueries}
            smMargin="20px 0"
            smFlexWrap="wrap"
            smJustifyContent="center"
          >
            <Block
              component="p"
              color={WHITE}
              textAlign="center"
              padding="0 15px"
              fontWeight="bold"
              textTransform=" capitalize"
              mediaQueries={mediaQueries}
              smMargin="10px 0"
            >
              {`780 Third Avenue`}
            </Block>
            <Block
              component="p"
              color={WHITE}
              textAlign="center"
              fontWeight="bold"
              padding="0 15px"
              textTransform=" capitalize"
              borderRight="1px solid grey"
              borderLeft="1px solid grey"
              mediaQueries={mediaQueries}
              smMargin="10px 0"
            >
              {`Suite 401`}
            </Block>
            <Block
              component="p"
              color={WHITE}
              textAlign="center"
              padding="0 15px"
              textTransform="capitalize"
              fontWeight="bold"
              mediaQueries={mediaQueries}
              smMargin="10px 0"
            >
              {`new york, NY 10017`}
            </Block>
          </Row>
          <Block
            component="span"
            color={WHITE}
            padding="0 15px"
            textTransform="uppercase"
            fontWeight="bold"
          >
            {`Call us at:`}
            <Link
              href="tel: 1-212-427-9700"
              style={{
                color: WHITE,
                margin: '0 10px',
              }}
            >
              {`212.427.9700`}
            </Link>
          </Block>
        </Col>
      </Row>
      <Row height="100%" alignItems="flex-start" justifyContent="flex-start">
      <Link
          href="https://www.dos.ny.gov/licensing/docs/FairHousingNotice_new.pdf"
          style={{
            color: WHITE,
            margin: '0 10px',
            textTransform: 'capitalize',
            textDecoration: 'underline',
            fontWeight: 'bold',
          }}
        >
          {`Fair Housing Notice`}
        </Link>
        <a
          href="/466.15-notice-tenants-reasonable-accommodation.pdf"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: WHITE,
            margin: '0 10px',
            textTransform: 'capitalize',
            textDecoration: 'underline',
            fontWeight: 'bold',
          }}
        >
          {`Disability Accommodations`}
        </a>
        <Link
          href="/privacy-policy/"
          style={{
            color: WHITE,
            margin: '0 10px',
            textTransform: 'capitalize',
            textDecoration: 'underline',
            fontWeight: 'bold',
          }}
        >
          {`privacy policy`}
        </Link>
        <Link
          href="/terms-of-use/"
          style={{
            color: WHITE,
            margin: '0 10px',
            textTransform: 'capitalize',
            textDecoration: 'underline',
            fontWeight: 'bold',
          }}
        >
          {`Terms of Use`}
        </Link>
      </Row>
    </Row>
    <Row
      justifyContent="space-between"
      alignItems="center"
      mediaQueries={mediaQueries}
      smFlexDirection="column"
    >
      <Link
        href="https://www.instagram.com/fetnernyc/"
        ariaLabel="Learn more about fetner on its instagram page"
      >
        <Instagram fillColor={WHITE} />
      </Link>
      <Row>
        <Block margin="10px">
          <Brand />
        </Block>
        <Block margin="10px">
          <Eho />
        </Block>
      </Row>
    </Row>
  </Col>
);
