import getWindow from './getWindow';

export default function isDOMInstance(node, type = Node) {
  if (node instanceof type) return true;
  const window = getWindow(node);
  if (window) {
    type = window[type.name] || type;
  }
  return node instanceof type;
}
