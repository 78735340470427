import {useState, useEffect, useRef} from 'react';
import useRefCallback from '../use-ref-callback';
import {getScrollRect, useNearestScrollNodeRef, useScrollEffect} from './utils';
import {isDOMInstance} from '../dom-utils';

function intersects(bounds, rect) {
  const {
    top = rect.top,
    right = rect.width,
    bottom = rect.height,
    left = rect.left,
  } = bounds;

  const inRangeVertical =
    typeof rect.top === 'number' &&
    typeof top === 'number' &&
    top <= rect.top &&
    typeof bottom === 'number' &&
    bottom >= rect.top;

  const inRangeHorizontal =
    typeof rect.left === 'number' &&
    typeof left === 'number' &&
    left <= rect.left &&
    typeof right === 'number' &&
    right >= rect.left;

  return inRangeVertical && inRangeHorizontal;
}

export function getIntersects(event, config) {
  if (!config) return false;
  const target = event.currentTarget;
  if (isDOMInstance(target, HTMLElement) || isDOMInstance(target, Document)) {
    const rect = getScrollRect(target);
    if (Array.isArray(config)) {
      return config.map(c => intersects(c, rect));
    } else {
      return intersects(config, rect);
    }
  }
  return false;
}

function useScrollIntersection(providedRefOrConfig, config) {
  let providedRef;
  if (`current` in providedRefOrConfig) {
    providedRef = providedRefOrConfig;
  } else {
    config = providedRefOrConfig;
  }
  // Keep track of changes to intersection config.
  const intersectionConfig = useRef(config);
  useEffect(() => {
    intersectionConfig.current = config;
  }, [config]);

  // Keep track of whether or not any configured areas
  // interesect the scroll position.
  const [intersectss, setIntersectss] = useState(() => {
    return Array.isArray(intersectionConfig.current)
      ? new Array(intersectionConfig.current.length).fill(false)
      : false;
  });

  // Keep a ref to the nearest scrollable container.
  const [ref, setRef] = useRefCallback(null);
  if (providedRef) setRef(providedRef.current);
  const scrollRef = useNearestScrollNodeRef(ref);

  // Subscribe to scroll events on the nearest scrolling element,
  // calling the handler whenever a scroll event occurs.
  useScrollEffect(
    scrollRef,
    function handleScrolChange(event: Event) {
      setIntersectss(getIntersects(event, intersectionConfig.current));
    },
    [],
  );

  // If a ref has been provided, just return the `Intersects` value.
  // If a ref has not not been provided, return a callback ref along with
  // the `Intersects` value.
  return providedRef ? intersectss : [intersectss, setRef];
}

export default useScrollIntersection;
