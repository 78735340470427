import {useState, useRef, useEffect, useMemo, useCallback} from 'react';

function throttle(func, threshold = 250, scope) {
  let last, deferTimer;
  return function(ctx) {
    const context = scope || ctx;
    const now = Date.now();
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    if (last && now < last + threshold) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function() {
        last = now;
        func.apply(context, args);
      }, threshold);
    } else {
      last = now;
      func.apply(context, args);
    }
  };
}

const isClient = typeof window !== undefined && typeof window !== 'undefined';

const useMountedRef = () => {
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  return mounted;
};

const getWindowSize = ({initialWidth, initialHeight}) => {
  if (isClient) return {width: window.innerWidth, height: window.innerHeight};
  return {width: initialWidth, height: initialHeight};
};

const windowSizeSubscribers = new Set();

const dispatchWindowSizeChange = () => {
  windowSizeSubscribers.forEach(cb => cb());
};

const subscribeToWindowSizeChange = callback => {
  const shouldAddListener = !windowSizeSubscribers.size && isClient;
  windowSizeSubscribers.add(callback);
  if (shouldAddListener) {
    window.addEventListener('resize', dispatchWindowSizeChange);
  }
  return function unsubscribeFromWindowSizeChange() {
    windowSizeSubscribers.delete(callback);
    if (!windowSizeSubscribers.size && isClient) {
      window.removeEventListener('resize', dispatchWindowSizeChange);
    }
  };
};

function useWindowSizeOptions({
  throttleMs = 0,
  initialWidth = 0,
  initialHeight = 0,
}) {
  return useMemo(() => {
    return {throttleMs, initialWidth, initialHeight};
  }, [throttleMs, initialWidth, initialHeight]);
}

function useWindowSize(handlerOrOptions, windowSizeOptions) {
  const changeHandler = useRef(null);
  if (typeof handlerOrOptions === 'function') {
    changeHandler.current = handlerOrOptions;
  } else {
    windowSizeOptions = handlerOrOptions;
  }

  const options = useWindowSizeOptions(
    {"throttleMs": 0,
    "initialWidth": 0,
    "initialHeight": 0
  });

  const [size, setSize] = useState(() => getWindowSize(options));

  const updateWindowSize = useMemo(() => {
    function anotherUpdateWindowSize() {
      const cb = changeHandler.current;
      const ssize = getWindowSize(options);
      if (typeof cb === 'function') {
        return cb(ssize);
      }
      setSize(ssize);
    }
    return options.throttleMs
      ? throttle(anotherUpdateWindowSize, options.throttleMs)
      : anotherUpdateWindowSize;
  }, [options]);

  const mounted = useMountedRef();

  const handleSizeChange = useCallback(
    () => mounted.current && updateWindowSize(),
    [updateWindowSize, mounted],
  );

  useEffect(() => {
    subscribeToWindowSizeChange(handleSizeChange);
    handleSizeChange();
  }, [handleSizeChange]);

  if (!changeHandler.current) {
    return size;
  }
}

export {useWindowSize};

export default function WindowSizeMonitor(props) {
  const size = useWindowSize();
  return props.children(size);
}
