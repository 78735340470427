// @flow

import React, {useState, useLayoutEffect} from 'react';
import {Col} from 'jsxstyle';
import {useSpring, animated} from 'react-spring';

import MenuDropdownLink from './MenuDropdownLink';
import {LIGHT_GREY} from '@styles/colors';

import type {Element} from 'react';

type Props = {
  dropdownItems: Object[],
  updatedNav: boolean,
  expanded: boolean,
  subDropDown?: boolean,
};

const mediaQueries = {
  sm: 'screen and (max-width: 1024px)',
  lg: 'screen and (min-width: 1280px)',
};

const AnimatedDropdown = animated(Col);

export default function MenuDropdown({
  dropdownItems,
  updatedNav,
  expanded,
  subDropDown
}: Props): Element<*> {
  const [visible, setVisible] = useState(false);
  const [currentHeight, setCurrentHeight] = useState(0);

  const DROPDOWN_HEIGHT = dropdownItems.length * 30;
  const {height, padding} = useSpring({
    height: expanded ? DROPDOWN_HEIGHT : 0,
    padding: expanded ? 20 : 0,
    onFrame({height}) {
      setCurrentHeight(height);
    },
  });

  useLayoutEffect(() => {
    if (currentHeight > DROPDOWN_HEIGHT / 2) {
      setVisible(true);
    }
    return () => {
      setVisible(false);
    };
  }, [currentHeight, expanded]);

  return (
    <AnimatedDropdown
      component="ul"
      listStyle="none"
      padding={padding.interpolate(v => `${v}px`)}
      position="absolute"
      background={LIGHT_GREY}
      width={200}
      height={height.interpolate(v => `${v}px`)}
      top={!subDropDown ? (updatedNav ? '70px' : '100px') : '110px'}
      mediaQueries={mediaQueries}
      smMaxHeight={200}
      smPosition="relative"
      smAlignItems="flex-start"
      smTop="0px !important"
      boxShadow="0px 5px 20px 0 rgba(45, 50, 52, 0.25)"
      willChange="background, padding, height, top"
    >
      {visible &&
        dropdownItems.map(menuItem => (
          <MenuDropdownLink key={menuItem.id} {...menuItem} />
        ))}
    </AnimatedDropdown>
  );
}
